import React, {useEffect} from 'react';
import {connect} from 'react-redux'
import { HashLink as Link } from 'react-router-hash-link'

import Navigation from './Navigation';
import { toggleNav, toggleNavScroll, disableNav } from '../../store/actions'

import logo from './logo.png';
import styles from './Header.module.scss'

const Header = ({navState, navScrolled, dispatch}) => {

  useEffect(() => {
    document.addEventListener('scroll', handleScrollToggle);
  })

  const handleScrollToggle = () => {
    window.pageYOffset > 100 ? dispatch(toggleNavScroll('scrolled')) : dispatch(toggleNavScroll(''))
  }

  const handleNavToggle = () => {
    dispatch(toggleNav)
  }

  const handleNavClick = () => {
    dispatch(disableNav)
  }

  const headerStyles = [styles.Header, navScrolled]

  return (
    <header className={headerStyles.join(' ')}>
      <div className="Container">
        <Link smooth to='/'>
          <img src={logo} className={styles.Logo} alt="Caya" />
        </Link>
        <Navigation handleNavToggle={handleNavToggle} navState={navState} disableNav={handleNavClick} />
      </div>      
    </header>
  );
}

const mapStateToProps = state => {
  return {
    navState: state.navToggle,
    navScrolled: state.navScrollToggle
  }
}

export default connect(mapStateToProps)(Header);
