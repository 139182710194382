import React from 'react'
import PropTypes from 'prop-types'

import styles from './Header.module.scss'

const Hamburger = ({navState, handleNavToggle}) => {

  const hambugerClasses = [styles.Hamburger, (navState && styles.open)]

  return (
    <div className={hambugerClasses.join(' ')} onClick={handleNavToggle}>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  )
}

Hamburger.propTypes = {
  navState: PropTypes.bool.isRequired,
  handleNavToggle: PropTypes.func.isRequired,
}

export default Hamburger
