import React from 'react'
import PropTypes from 'prop-types'

const Button = ({label, type, onclick}) => {
  return <button type={type ? type : 'button'} onClick={onclick} className="button">{label}</button>
}

Button.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  onclick: PropTypes.func
}

export default Button
