import * as actions from './types'

// const submitEnquiry = () => {
//   axios.post('API_URI', data)
//   .then( res => {
//       this.setState({ sent: true }, this.resetForm())
//   })
//   .catch( () => {
//     console.log('Message not sent')
//   })
// }


export const toggleNav = {
  type: actions.TOGGLE_NAV
}

export const disableNav = {
  type: actions.DISABLE_NAV
}

export const toggleNavScroll = (scrollState) => {
  return {
    type: actions.TOGGLE_NAV_SCROLL,
    payload: scrollState
  }
}

export const attemptEnquiry = (formData) => {
  return {
    type: actions.ATTEMPT_SUBMIT_FORM,
    payload: formData
  }
}

export const resetEnquiry = {
  type: actions.CLEAR_SUBMIT_FORM,
}


