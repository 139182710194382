import React from 'react'
import { Facebook } from './Facebook'
import background from './events.jpg'

const NewsEvents = props => {
  return (
    <section id="news" style={{backgroundImage:`url(${background})`}}>
      <div className="content">
        <h2 style={{color:'white'}}>News / Events</h2>
        <Facebook />
      </div>
    </section>
  )
}

export default NewsEvents
