import React from 'react'
import { Link } from 'react-router-dom'

const styles = {
  display: 'flex',
  justifyContent: 'space-between'
} 

export const Footer = () => {
  return (
    <footer style={styles}>
      <div>
        &copy; 2020 Come As You Are | <Link to="/policy" style={{color: 'white'}}>Policy</Link>
      </div>
      <div>
       <a href="http://viaverso.co.uk" target="_blank" rel="noopener noreferrer" style={{color: 'white'}}>ViaVerso</a>
      </div>
    </footer>
  )
}
