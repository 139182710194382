import React, {useState} from 'react';
import {BrowserRouter, Route} from 'react-router-dom'
import './App.css';
import Header from './components/Header/Header';
import Hero from './pages/Hero/Hero';
import About from './pages/About/About';
import NewsEvents from './pages/NewsEvents/NewsEvents';
import Services from './pages/Services/Services';
import Press from './pages/Press/Press';
import Contact from './pages/Contact/Contact';
import Collaborations from './pages/Collaborations/Collaborations';
import Policy from './pages/Policy/Policy';
import { Footer } from './components/Footer/Footer';

function App() {

  const [navToggle, setNavToggle] = useState();

  const handleNavToggle = () => {
    setNavToggle(!navToggle)
  }

  return (
    <BrowserRouter>
      <Header navState={navToggle} onclick={handleNavToggle} />

      <Route path="/policy">
        <Policy />
      </Route>
      <Route path="/" exact>
        <Hero />
        <About />
        <NewsEvents />
        <Collaborations /> 
        <Services />
        <Press />
        <Contact />
      </Route>

      <Footer />
    </BrowserRouter>
  );
}

export default App;
