import React from 'react'
import Tile from '../../components/Tile/Tile'

import bbcRadio from './images/bbc-radio.png'
import boilerRoom from './images/boiler-room.png'
import britishLibrary from './images/british-library.png'
import goldsmiths from './images/goldsmiths.png'
import londonFashionWeek from './images/london-fashion-week.png'
import mueumOfLondon from './images/mol.png'
import PRS from './images/prs.png'
import somerset from './images/somerset.png'
import WestministerUniversity from './images/uow.png'

import styles from './Collaborations.module.scss'

const Collaborations = () => {
  return (
    <section id="collaborations" className={styles.Collaborations}>
      <h2 className="center">Collaborations</h2>
      <Tile img={bbcRadio} title="BBC Radio" />
      <Tile img={PRS} title="PRS for Music" />
      <Tile img={boilerRoom} title="Boiler Room" />
      <Tile img={britishLibrary} title="British Library" />
      <Tile img={WestministerUniversity} title="University of Westminister" />
      <Tile img={goldsmiths} title="Goldsmiths University" />
      <Tile img={mueumOfLondon} title="Museum of London" />
      <Tile img={londonFashionWeek} title="London Fashion Week" />
      <Tile img={somerset} title="Somerset House" />
    </section>
  )
}

export default Collaborations