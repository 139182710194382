import React from 'react'

import styles from './Press.module.scss'
import pressImage from './press.jpg'
import pressKit from './CayaSoundEPKofficial.pdf'

const Press = props => {
  return (
    <section id="press" className={styles.Press}>
      <div className={`${styles.content} content list`}>
        <h2>Press</h2>
        <a target="_blank" rel="noopener noreferrer" href="https://amp.theguardian.com/music/2019/jun/19/reggae-sound-systems-naples">The Guardian:- How reggae brought hope to Naples</a>
        <a target="_blank" rel="noopener noreferrer" href="http://www.skindeepmag.com/online-articles/skin-deep-meets-sound-system-culture/">Skin Deep Magazine:- Meets Sound System Culture</a>
        <a target="_blank" rel="noopener noreferrer" href="http://www.gal-dem.com/thali-lotus-caya-soundsystem/">Gal dem:- In conversation with Thali Lotus</a>
        <a target="_blank" rel="noopener noreferrer" href="https://artsperformedpodcast.podbean.com/e/thali-lotus-caya-sound-system-operator/">Arts performed:- Thali Lotus CAYA: Sound System Operator</a>
        <a target="_blank" rel="noopener noreferrer" href="https://www.gold.ac.uk/calendar/?id=11204">Lets Play Vinyl- A Touring Exhbition Celebrating the New Generation of UK Sound Systems- January 2018</a>
        <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=O117TF2P688&feature=youtu.be">Rise Of The Omega Short Film- ROTO 2018</a>
        <a className="button" href={pressKit} target="_blank" rel="noopener noreferrer" download>Download Press Kit</a>
      </div>
      <img src={pressImage} alt="Press" className="section-image" />
    </section>
  )
}

export default Press
