import * as actions from './types'

const INITIAL_STATE = {
  navToggle: false,
  navScrollToggle: '',
  contactForm: {
    name: '',
    email: '',
    message: '',
    status: '',
    sent: false
  }
}

const reducer = (state=INITIAL_STATE, action) => {
  switch (action.type) {
    case actions.DISABLE_NAV:
      return {
        ...state,
        navToggle: false
      }
    case actions.TOGGLE_NAV:
      return {
        ...state,
        navToggle: !state.navToggle
      }
    case actions.TOGGLE_NAV_SCROLL:
      return {
        ...state,
        navScrollToggle: action.payload
      }
    case actions.ATTEMPT_SUBMIT_FORM:
      return {
        ...state,
        contactForm: {
          ...state.contactForm,
          name: action.payload.name,
          email: action.payload.email,
          message: action.payload.message,
          status: 'sending'
        }
      }
    case actions.CLEAR_SUBMIT_FORM:
      return {
        ...state,
        contactForm: {
          ...state.contactForm,
          name: '',
          email: '',
          message: '',
          status: ''
        }
      }
    default:
      return state
  }
}

export default reducer