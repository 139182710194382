import React, {useEffect, useState} from 'react'
import background from './hero.jpg'
import styles from './Hero.module.scss'

const Hero = props => {

  useEffect(() => {
    document.addEventListener('scroll', handleContentFade);
  })

  const [scrollVal, setScrollVal] = useState(1)

  const handleContentFade = () => {
    const opacityVal = 100 - (window.pageYOffset / 5)
    opacityVal > 0 && setScrollVal(opacityVal / 100)
  }

  return (
    <section id="hero" className={styles.Hero} style={{backgroundImage:`url(${background})`}}>
      <div style={{opacity:scrollVal}}>
        <h1>Come As You Are</h1>
        <h2>C A Y A</h2>
      </div>
    </section>
  )
}

export default Hero
