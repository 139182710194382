import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'

const Input = ({name, type, placeholder, required, reset}) => {

  const [inputVal, setInputVal] = useState('')
  useEffect(() => {
    reset && setInputVal('')
  }, [reset])

  switch(type){
    case 'textarea':
      return  (
        <textarea name={name} placeholder={placeholder} onChange={e => setInputVal(e.target.value)} required={required} value={inputVal} />
      )    
    default:
      return (
        <input name={name}
          type={type}
          placeholder={placeholder}
          value={inputVal}
          onChange={e => setInputVal(e.target.value)} 
          required={required}
        />
      )  
  } 
}

Input.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string.isRequired,
  reset: PropTypes.bool,
  required: PropTypes.bool
}

export default Input
