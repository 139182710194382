import React, {useState} from 'react'
import axios from 'axios'

import Input from '../../components/Input/Input'
import Button from '../../components/Button/Button'

import styles from './Contact.module.scss'

const Contact = () => {

  const [formStatus, setFormStatus] = useState('Submit')

  const handleSubmitForm = (event) => {
    event.preventDefault();
    setFormStatus('Sending...')
    
    const data = new FormData(event.target);
    let formData = {
      name: data.get('name'),
      email: data.get('email'),
      message: data.get('comment')
    }

    axios.post('https://cayasounds.co.uk/mail/sendMail.php', formData)
      .then(() => {
        setFormStatus('Sent')
        setTimeout(() => { setFormStatus('Submit') }, 2000)
      })
      .catch(() => {
        setFormStatus('Not Sent? Try Again.')
      })
  }

  return (
    <>
      <section id="contact" className={styles.Contact}>
        <form className={styles.Form} onSubmit={handleSubmitForm}>
          <h2>Contact</h2>
          <Input type="name" name="name" placeholder="Name" reset={formStatus==='Sent' && true} required />
          <Input type="email" name="email" placeholder="Email" reset={formStatus==='Sent' && true} required />
          <Input type="textarea" name="comment" placeholder="Comment/Enquiry" reset={formStatus==='Sent' && true} />
          <Button type="submit" label={formStatus} />
        </form>
        
        <div>
          <h3>Contact Details</h3>
          <p>Thali Lotus</p>
          <p>thali@comeasuare.com</p>
        </div>
      </section>
      <section id="contact" style={{background:'#e5e5e5'}}>
        <div class="content center">
          <h4>EQUALITY DIVERSITY &amp; INCLUSION</h4>
          <p>I and everything CAYA are passionate in the belief that everyone has the right to receive the same level of entertainment, education and empowerment. CAYA provides services which are inclusive of all ethnic communities, gender groups and abilities. I value and strive to deliver services and entertainment to meet people’s wellbeing and elevation, which reflects the diverse population of my cultural heritage and communities.</p>
          <p>Fortunate to stem from one of the largest and most culturally diverse cities in the world, London, for CAYA equality is about fairness and Coming- As -You- Are and making constructive use of diversity. CAYA encourages engaging services and entertainment where people feel comfortable in drawing on their personal and cultural heritages.</p>
        </div>
      </section>
    </>
  )
}

export default Contact