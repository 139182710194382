import React from 'react'
import PropTypes from 'prop-types'
import { HashLink as Link } from 'react-router-hash-link'

import Hamburger from './Hamburger'
import styles from './Header.module.scss'

const Navigation = ({navState, handleNavToggle, disableNav}) => {

  const navigtionLinks = {
    about: "About",
    news: "News/Events",
    // workshops: "Workshops",
    collaborations: "Collaborations",
    services: "Services",
    press: "Press",
    contact: "Contact Us"
  }

  return (
    <nav className="Navigation">
      <Hamburger handleNavToggle={handleNavToggle} navState={navState} />
      <ul className={navState ? styles.open : ''}>
        {Object.keys(navigtionLinks).map(nav => <Link key={nav} smooth to={`/#${nav}`} onClick={disableNav}>{navigtionLinks[nav]}</Link>)}
      </ul>
    </nav>
  )
}

Navigation.propTypes = {
  navState: PropTypes.bool.isRequired,
}

export default Navigation