import React from 'react'
import workshopsEducation from './workshops.jpg'
import workshopsAcademic from './workshops2.jpg'
import image from './soundHire.jpg'
import styles from './Services.module.scss'


const Services = props => {
  return (
    <section id="services" className={styles.Services}>
      <h2 className="center">Services</h2>
      <h3 className={`${styles.heading} center`}>CAYA - Education Workshop</h3>
      <div className="content columns">
        <img width="100%" src={workshopsEducation} alt="CAYA Education Workshop" />
        <h5>WHAT IT IS:</h5>
        <p>CAYA Educational workshops, take place mainly during the academic school terms, with the main times of activity being between October and March with celebrations of Black History Month &amp; World Book Day. The workshops are 4hrs long and either morning or afternoon sessions.</p>
        <p>The Workshops are aimed at children between the ages of 6-11, and in incorporates learning for all children. However, in particular those who are from BAME backgrounds as this education lends itself to their cultural backgrounds.</p>
        <h5>ACHIEVEMENTS:</h5>
        <p>Pupils on completion of the course achieve; understanding of the history of Soundsystem Culture &amp; Jamaican Music, understanding of key components of a Soundsystem; turntable speakers, mics, records, preamp, understanding of the history of Dub &amp; Reggae Music, understanding of a soundsystem Crew</p>
        <h5>VALUE:</h5>
        <p>The value these workshops adds to society is understanding of diverse communities. On the whole children are educated about their own history as well as those of others are more likely to be respectful and engaging of that diversity.</p>
      </div>
      <h3 className={`${styles.heading} center`}>Institute of Sound &amp; Vibration ISSV- CAYA Academic Talks</h3>
      <div className="content columns">
        <h5>WHAT IT IS:</h5>
        <p>ISSV- The Institute of Sound &amp; Vibration are specific academic papers and talks which are aimed at researchers, higher education students, practitioners and researchers</p>
        <p>The ISSV sessions are based around the academic calendar but are not limited to this. To date most Talks have been in collaboration with Goldsmiths University &amp; University of Westminster. In addition, ISSV have been part of radio talks and online content.</p>
        <h5>ACHIEVEMENTS:</h5>
        <p>The ISSV sessions are based around the academic calendar but are not limited to this. To date most Talks have been in collaboration with Goldsmiths University &amp;University of Westminster. In addition, ISSV have been part of radio talks and online content.</p>
        <h5>HOW IT HELPS:</h5>
        <p>By providing insights from both practitioner and academic perspectives. The content of the talks/lectures supports ideologies around the noted concepts but also acts as a key evidence base for further research and development.</p>
        <img width="100%" src={workshopsAcademic} alt="CAYA Academic Workshop" />
        <h5>VALUE:</h5>
        <p>ISSV adds value by providing an evidence base for further research and is able to inform and educate. ISSV adds to disciplines, such as culture, history, anthropology, biology and physics.</p>
      </div>
      <p>&nbsp;</p>
      <div className="content">
        <img width="100%" src={image} alt="Hire Sound System" />
      </div>  
      <h3 className={`${styles.heading} center`}>CAYA SOUNDSYSTEM - Live Events</h3>
      <div className="content columns">
        <h5>WHAT IT IS:</h5>
        <p>CAYA Soundsystem is a live audio event made up of 16 loudspeakers, a turntable, cdj player, vinyl’s, effects machine, x 3 amplifiers, preamp, microphones, lighting and travel cases.</p>
        <p>CAYA Soundsystem is active all year round and is in keeping with the Festival season and other Cultural dates. However, it is not specific to this. CAYA Soundsystem has featured as part of Art Exhibitions with University of Westminster, Academic research studies with Imperial College, Societal events, International Women’s Day and Cultural events Africa Liberation Day.</p>
        <h5>ACHIEVEMENTS:</h5>
        <p>On attending a CAYA soundsystem event, the benefits are uplifting music, spiritual meditation, cultural education and historical understanding. CAYA Soundsystem helps individuals by providing musical and cultural experiences, helps society by maintaining cultural events that help to educate on diversity.</p>
        <h5>VALUE:</h5>
        <p>Entertainment, Empowerment and Education</p>
      </div>
      <h3 className={`${styles.heading} center`}>CAYA DJ Sessions – Live Events</h3>
      <div className="content columns">
        <h5>WHAT IT IS:</h5>
        <p>Live DJ sets by Thali Lotus- Active all year round</p>
        <h5>ACHIEVEMENTS:</h5>
        <p>To date I have performed an array of DJ sets that include but not limited to, Boiler Room as part of GAIKAs 2018 exhibition at Somerset House, The British Library, Wee Dub Festival &amp; Beat herder Festival.</p>
        <h5>HOW IT HELPS:</h5>
        <p>The DJ Sets help to maintain an active presence of cultural music derived from African and Caribbean History.</p>
        <h5>VALUE:</h5>
        <p>Entertaining, Empowering and Educating</p>
      </div>
      <h3 className={`${styles.heading} center`}>UPCOMING CAYA – ALTRUISM JAN 2021</h3>
      <div className="content columns">
        <h5>WHAT IT IS:</h5>
        <p>An online Conference, E-Music Showcase, Design competition, Live Event &amp; Design Competition. This project supports diversity, inclusion and equality within the STEM, manufacturing and cultural industries. With a focus on audio and sound, the project is delivered in collaboration with a broad spectrum of industry partners to inspire girls and young women, BAME and people from disadvantaged backgrounds to consider careers, leadership roles, and business start-up within these industries.</p>
        <h5>HOW IT HELPS:</h5>
        <p>Through this course, participants will develop a brad spectrum of abilities. These include leadership, business, professional, personal and intrapersonal development. They will also gain an insight into cultural themes, identity and an understanding of diversity, inclusion and equality and why these are important ethics in building a fairer more respectful society.</p>
        <h5>VALUE:</h5>
        <p>They will learn essential life skills which include communication and negotiation, positive expression, reasoning confidence, emotional intelligence critical thinking, decision making and team work.</p>
        <h5>CONTRIBUTORS &amp; PARTNERSHIPS:</h5>
        <p>Manufacturing companies, Business Consultants, Space Agencies, Audio &amp; Acoustic companies, Audio &amp; Sound Production specialist. BBC RADIO London DJs &amp; Festival</p>
      </div>
    </section>
  )
}

export default Services
