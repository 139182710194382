import React from 'react'

import styles from './About.module.scss'

const About = props => {
  // import { getContent } from '../../api/getContent'
  // useEffect(() => {
  //   getContent('home')
  // })

  return (
    <section id="about" className={styles.About}>
      <h2 className="center">About Me</h2>
      <h4 className="center">(Music) x (Creativity) -  Limitations = CAYA</h4>
      <div className="content columns">
        <p>An 80s baby, I was born into a Soundsystem Household. My Dad ran a Sound ‘B Force and
  so vibrations have been part of my existence, always. A Born Creative, music was my outlet
  and CAYA a natural progression from listening to playing music. CAYA existed way before
  the speakers and turntables and was an imaginary friend that I sort comfort and refuge in. It
  was a great pleasure and achievement then when I was able to manifest CAYA physically.</p>
        <p>I attended the Brits performing Arts School, gained a BA &amp; MSc but it was until I really
harnessed my creativity that things took shape. Journeying through meditation and
philosophical probing questions that I was led to the vibes of Roots, Reggae music and this
is where Come As You Are began.</p>
        <p>Influenced by the energy of Carnival, feminine empowerment and the UK Roots &amp; Dub
scene CAYA was founded in November 2016. Fast forward to 2020, I’m 1 of only 3 Female
Soundsystems in the UK and regarded by the Guardian 2019 as the ‘creator of autonomous
zones’ and responsible for Entertaining, Educating &amp; Empowering through the power of
Music. A Skilled Engineer, Academic, Author Curator and Innovator I have become
extremely popular for my innovative approach to the traditions of Soundsystem Culture. The
future is most definitely CAYA.</p>
      </div>
      <div className={styles.Video}>
        <iframe src="https://www.youtube.com/embed/WnFo1jTs4aI?rel=0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" title="CAYA" allowfullscreen></iframe>
      </div>
    </section>
  )
}

export default About
