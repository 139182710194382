import React from 'react'
import PropTypes from 'prop-types'

const Tile = ({title, img}) => {
  return <img src={img} alt={title} />
}

Tile.propTypes = {
  title: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
}

export default Tile
