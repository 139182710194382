import React from 'react'

const Policy = () => {
  React.useEffect(() => {
    window.scrollTo(0,0)
  }, [])

  return (
    <section id="about" className="content">
      <h1>
        Privacy Policy
      </h1>
      <ul>
        <li>
          <a href="#w-privacypolicy-2">Introduction</a>
          <ul>
            <li><a href="#w-privacypolicy-2-1">Data controller</a></li>
            <li><a href="#w-privacypolicy-2-2">Lawful basis for processing</a></li>
            <li><a href="#w-privacypolicy-2-3">Individual rights</a></li>
            <li><a href="#w-privacypolicy-2-4">Security</a></li>
            <li><a href="#w-privacypolicy-2-5">Disclosures</a></li>
            <li><a href="#w-privacypolicy-2-6">Changes to this privacy policy</a></li>
          </ul>
        </li>
        <li><a href="#w-privacypolicy-3">Cookies</a></li>
        <li><a href="#w-privacypolicy-4">Data collected when you contact us</a>
          <ul>
            <li><a href="#w-privacypolicy-4-2">Website contact form, email or telephone</a></li>
          </ul>
        </li>
        <li><a href="#w-privacypolicy-6">Data collected by third parties on our behalf</a>
          <ul>
            <li><a href="#w-privacypolicy-6-2">Cloudflare</a></li>
            <li><a href="#w-privacypolicy-6-3">Google Analytics</a></li>
          </ul>
        </li>
        <li><a href="#w-privacypolicy-7">Other data collected by third parties</a>
          <ul>
            <li><a href="#w-privacypolicy-7-1">Facebook page widget</a></li>
            <li><a href="#w-privacypolicy-7-4">Twitter profile widget</a></li>
            <li><a href="#w-privacypolicy-7-6">YouTube video player</a></li>
          </ul>
        </li>
      </ul>
      <h2 id="w-privacypolicy-2">
        Introduction
      </h2>
      <p>
        We protect your personal data in line with the requirements of the <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/PDF/?uri=CELEX:32016R0679&amp;from=EN">General Data Protection Regulation (GDPR)</a>. The GDPR requires data controllers such as ourselves to document our lawful basis for processing personal data. It also gives you rights over how your data is processed. This privacy policy documents the data we collect, why and how we process it, and how to exercise your rights.
      </p>
      <h3 id="w-privacypolicy-2-1">
        Data controller
      </h3>
      <p>
        The data controller responsible for this website is Caya Sounds, who can be contacted at thali@comeasuare.com.
      </p>
      <p>
        This website contains links to third-party websites, which have their own data controllers and privacy policies. This privacy policy applies only to this website.
      </p>
      <h3 id="w-privacypolicy-2-2">
        Lawful basis for processing
      </h3>
      <p>
        For each method by which we collect personal data, this privacy policy documents our lawful basis for processing the data. Where we rely on your consent to process your data, we explain how you can withdraw your consent and delete your data.
      </p>
      <h3 id="w-privacypolicy-2-3">
        Individual rights
      </h3>
      <p>
        The GDPR gives you rights over how your personal data is processed. You can exercise your rights by contacting us. In some cases you can also exercise your rights through automated systems, as described at the relevant points in this privacy policy.
      </p>
      <h3 id="w-privacypolicy-2-4">
        Security
      </h3>
      <p>
        The GDPR requires us to implement appropriate technical measures to protect data. We verify the identity of any individual who requests access to data before granting access. We use Transport Layer Security (TLS, also known as SSL) to encrypt any data you supply to us through our website. Additional technical measures are described at the relevant points in this privacy policy.
      </p>
      <h3 id="w-privacypolicy-2-5">
        Disclosures
      </h3>
      <p>
        In addition to any sharing of data described elsewhere in this privacy policy, we may disclose data for legal reasons. If we suspect criminal activity we may disclose data relating to those involved or affected to the appropriate authorities. We may also be obliged to disclose data if we receive a request from an appropriate authority.
      </p>
      <h3 id="w-privacypolicy-2-6">
        Changes to this privacy policy
      </h3>
      <p>
        We may occasionally make changes to this privacy policy. Following any changes, the date at the top of this privacy policy will be updated. If any change allows for wider access to data, such changes will only apply to data collected after the date of the updated privacy policy.
      </p>
      <h2 id="w-privacypolicy-3">
        Cookies
      </h2>
      <p>
        Cookies are small pieces of text that are stored by your browser. Each cookie has a name and is associated with a particular site. When your browser sends a request to a site (for example, to download a page, image, or video), the computer that responds (known as a server) may tell your browser to set one or more cookies. When your browser makes further requests to the same site it sends the cookies back to the server. This allows the server to remember you as you browse the site, and provide features such as shopping baskets or password-protected areas. For more information on the cookies we use, see <a href="../cookies/">our cookie policy</a>.
      </p>
      <h2 id="w-privacypolicy-4">
        Data collected when you contact us
      </h2>
      <h3 id="w-privacypolicy-4-2">
        Website contact form, email or telephone
      </h3>
      <p>
        When you contact us by any of the above methods, typically we collect name, contact details, demographic information such as town or postcode, and why you might be interested in our services or a relevant contact for our business. <br />
      <br />
      Once you contract with us for a service, you become a customer of our business and we will collect other relevant information at the time of contracting with you. This typically includes email address, phone number and postal address and other service-specific information so that we can provide what we have contracted to, invoice you and keep proper records of our business relationship. <br />
      <br />
      We may use the information to improve our products and services. We will email you our half-termly newsletter about new services, special offers or other information which we think you may find interesting using the email address which you have provided. You can update your details or unsubscribe at any time by following the relevant links at the bottom of any newsletter. 
      </p>
      <p>
        <b>Lawful basis for processing:</b> Performance of a contract
        <br />
        <b>Why?</b> We need your contact details in order to respond to your enquiry about our services, and to continue our business relationship with you, should you become a customer.
      </p>
      <h2 id="w-privacypolicy-6">
        Data collected by third parties on our behalf
      </h2>
      <h3 id="w-privacypolicy-6-2">
        Cloudflare
      </h3>
      <p>
        Our site is served through Cloudflare. Cloudflare helps our site load faster by storing copies of our content in data centres around the world, and defends our site from attacks by logging requests to detect and block suspicious traffic. For more information on how Cloudflare handles the data it collects, see <a href="https://www.cloudflare.com/privacypolicy/">Cloudflare’s privacy policy</a>.
      </p>
      <p>
        <b>Lawful basis for processing:</b> Compliance with a legal obligation
        <br />
        <b>Why?</b> To comply with the GDPR obligation to implement appropriate technical measures to protect data
      </p>
      <h3 id="w-privacypolicy-6-3">
        Google Analytics
      </h3>
      <p>
        We use Google Analytics to track visitor interaction with our site in order to produce statistical reports. Google collects details of the pages you view and the time you viewed them, the features of your browser, and your IP address. We have enabled <a href="https://support.google.com/analytics/answer/2763052">IP anonymisation</a> so that Google will not store your complete IP address. For more information on how Google handles the data it collects, see <a href="https://policies.google.com/privacy">Google’s privacy policy</a>.<br /><br />To opt out of Google Analytics tracking on our site, see the Google Analytics section of <a href="../cookies/">our cookie policy</a>. To opt out of Google Analytics tracking on all sites, use the <a href="https://tools.google.com/dlpage/gaoptout">Google Analytics Opt-out Browser Add-on</a>.
      </p>
      <p>
        <b>Lawful basis for processing:</b> Pursuance of our legitimate interests
        <br />
        <b>Why?</b> To allow us to analyse how visitors interaction with our site in order to improve our site and our services
      </p>
      <h2 id="w-privacypolicy-7">
        Other data collected by third parties
      </h2>
      <h3 id="w-privacypolicy-7-1">
        Facebook page widget
      </h3>
      <p>
        When you view a page containing the Facebook page widget, your browser connects to Facebook. For more information on how Facebook handles the data it collects, see <a href="https://www.facebook.com/about/privacy">Facebook’s privacy policy</a>.
      </p>
      <h3 id="w-privacypolicy-7-4">
        Twitter profile widget
      </h3>
      <p>
        When you view a page containing the Twitter profile widget, your browser connects to Twitter. For more information on how Twitter handles the data it collects, see <a href="https://twitter.com/en/privacy">Twitter’s privacy policy</a>.
      </p>
      <h3 id="w-privacypolicy-7-6">
        YouTube video player
      </h3>
      <p>
        When you view a page containing the YouTube video player, your browser connects to YouTube. For more information on how Google (the operator of YouTube) handles the data it collects, see <a href="https://policies.google.com/privacy">Google’s privacy policy</a>.
      </p>    
    </section>
  )
}

export default Policy
